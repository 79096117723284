
import styled from 'styled-components'


const DemoGamesStyled = styled.div`
    height: 100vh;
    /* height: ${props => props.isLandscape ? '100vh' : 'calc(100vh + 100px)'}; */
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: black;

    .game {
        margin: 0;
        padding: 0;
        width: 100%;
    }
 `;

export default DemoGamesStyled