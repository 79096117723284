import React from "react"
import { graphql } from 'gatsby'
import DemoGamesStyled from './demoGamesStyled'



const DemoGames = (props) => {
  let isTouch
  if (typeof window !== 'undefined') {
    isTouch = typeof window.ontouchstart !== 'undefined';
}

  const { gameId } = props.data.contentfulCasinoGames
  const username = Math.ceil(Math.random() * 10000)
  const url = `https://poker-test-slots.cubeia.com/games/${gameId}?token=1:${username}&cur=EUR`

  if(isTouch) {
    return window.location = url
  }
  return (
    <DemoGamesStyled>
        <iframe src={url} width="100%" height="100%"
          frameBorder="0" allowFullScreen="" title="casino game" className="game" scrolling="no"></iframe>
    </DemoGamesStyled>
    )

}


export default DemoGames

export const pageQuery = graphql`
  query GamesByGameId($gameId: String!) {
    contentfulCasinoGames(gameId: { eq: $gameId }) {
        gameId
        id
    }
  }
`
